import { emailClient } from '@/api/clients'

const endpoints = {
  // CHECKLISTS
 
  email: '',

 
}

export default class EmailAPI {
  static async createEmail (email) {
    const response = await emailClient.post(endpoints.email, email)
    return response.data
  }
}
