<template>
  <div>
    <div v-if="!showConfirmation" class="main">
      <div class="title">Need help?</div>
      <div class="text">
        Select the task you need assistance with, and we’ll notify your leader.
      </div>
      <div class="multi-select-box">
        <div class="selected-items-area">
          <div class="placeholder" v-if="!numberSelected">Select</div>
          <div
            class="margin-0"
            v-for="(option, index) in options"
            @click="deselectOption(option, index)"
            :key="option"
          >
            <div class="multi-select-box-item" v-if="option.selected">
              {{ option.name }} <img class="x" :src="x" alt="" />
            </div>
          </div>
        </div>
        <div class="dropdown-arrow" @click="showDropdown = !showDropdown">
          <img :src="!showDropdown ? downArrow : upArrow" alt="" />
        </div>
      </div>
      <div class="multi-select-dropdown" v-if="showDropdown">
        <div
          :class="dropdownClass(option)"
          @click="selectOption(option, index)"
          v-for="option in options"
          :key="option"
        >
          {{ option.name }}
          <img
            class="selected-icon"
            v-if="option.selected"
            :src="selectedIcon"
            alt=""
          />
        </div>
      </div>
      <!-- <multi-select
      :options="options"

     /> -->
      <!-- <MultiSelect>
     </MultiSelect> -->
      <!-- <select class="select" name="" id="" >
         <option class="placeholder" value="" disabled selected>
                  Select
         </option>
         <option value="">Salesforce</option>
         <option value="">Teams(Apple Store)</option>
         <option value="">STOW</option>
         <option value="">Backsplash Ordering</option>
         <option value="">MeasurePro+ (THD App Store)</option>
    </select> -->

      <div v-if="showWarning" class="instructions">Please select a task</div>
      <div class="text">
        Add any information that may help your leader understand your problem
        area. (Optional)
      </div>
      <textarea v-model="response" class="input" type="text" />
      <div class="button-area">
        <div class="back" @click="emit('showModal', false)">Back</div>
        <div class="submit" @click="handleSubmit">Submit</div>
      </div>
    </div>
    <div v-if="showConfirmation" class="confirmation">
      <div class="title">Got it!</div>
      <div class="text">
        Your leader has been notified and will be in touch soon to help resolve
        the issue.
      </div>
      <div @click="emit('showModal', false)" class="continue">Continue</div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref, onMounted } from "vue";
import MultiSelect from "./MultiSelect.vue";
import "vue-multi-select/dist/lib/vue-multi-select.css";
import downArrow from "../assets/icons/down-arrow.png";
import upArrow from "../assets/icons/up-arrow.png";
import selectedIcon from "../assets/icons/selected.png";
import x from "../assets/icons/x.png";
import EmailAPI from "@/api/EmailAPI";
import { useStore } from "vuex";
const store = useStore();
const response = ref();
const numberSelected = ref(0);
const dropdownClass = (option) => {
  if (option.selected) return "selected option";
  else return "option";
};
const task = ref();
const message = ref();
const showWarning = ref(false);

const deselectOption = (option, index) => {
  numberSelected.value--;
  options.value[index].selected = false;
  selected.value.splice(index, 1);
};
const showConfirmation = ref(false);
const options = ref([
  //   {
  //     name: 'Salesforce',
  //     selected: false,
  //     index: 1
  //   },
  //   {
  //     name: 'Teams(Apple Store)',
  //     selected: false,
  //     index: 2
  //   },
  //   {
  //     name: 'STOW',
  //     selected: false,
  //     index: 3
  //   },
  //   {
  //     name: 'Backsplash Ordering',
  //     selected: false,
  //     index: 4
  //   },
  //   {
  //     name: 'MeasurePro+ (THD App Store)',
  //     selected: false,
  //     index: 5
  //   }
]);
const showDropdown = ref(false);
const selected = ref([]);
const props = defineProps({
  tasks: {},
  subtask: {},
});
onMounted(() => {
  console.log("assistance: ", props.tasks);
  props.tasks.forEach((task, index) => {
    console.log(task.name);
    let option;
    if (props.subtask) {
      option = {
        name: task.name,
        selected: false,
        index,
        type: "subtask",
        id: task.id,
      };
    } else {
      option = {
        name: task?.name,
        selected: false,
        index,
        type: "task",
        id: task?.id,
      };
    }
    options.value.push(option);
  })(options.value);
});

const handleSubmit = async () => {
  const issues = [];
  options.value.forEach((option) => {
    if (option.selected) issues.push(option.name);
  });
  if (!issues[0]) showWarning.value = true;
  else {
    showConfirmation.value = true;
    const user = store.state.user;
    const email = {
      subject: `Onboarding Journey Assistance Needed ${user.first_name} ${user.last_name}`,
      body: `${user.first_name} ${
        user.last_name
      } has requested assistance with the following task: ${
        selected.value[0].name
      } - ${response.value ? response.value : ""}`,
      manager_ldap: user.manager_ldap_id,
    };
    await EmailAPI.createEmail(email);
    emit("changeStatus", selected.value, response.value);
  }
};
const selectOption = (option, index) => {
  showDropdown.value = false;
  if (option.selected) numberSelected.value--;
  else numberSelected.value++;
  options.value.forEach((opt) => {
    opt.selected = false;
  });
  option.selected = !option.selected;
  // deselectOption(selected.value[0], )

  selected.value = [option];
};
const emit = defineEmits(["showModal", "changeStatus"]);
</script>

<style scoped>
div {
  margin: 15px;
}
.selected-icon {
  margin-left: auto;
}
.multi-select-box-item {
  height: 25px;
  display: flex;
  align-items: center;
  background: #eceaea;
  border-radius: 5px;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  /* identical to box height, or 12px */
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  padding: 10px;
  /* dark grey */
  cursor: pointer;
  color: #5f6562;
}
.x {
  margin: 0 0 0 8px;
}
.selected-items-area {
  overflow: scroll;
  margin: 0;
  display: flex;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.selected-items-area::-webkit-scrollbar {
  display: none;
}

.dropdown-arrow {
  margin-left: auto;
  margin-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 20px;
}
.multi-select-box {
  display: flex;
  margin: 0;
  align-items: center;
  width: 336px;
  height: 51px;
  left: 0px;
  top: 0px;

  background: #ffffff;
  /* HD Orange */

  border: 2px solid #f96302;
}
.multi-select-area {
  margin: 0;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.multi-select-area::-webkit-scrollbar {
  display: none;
}
.margin-0 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
}
.option {
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  /* or 17px */
  height: 40px;
  display: flex;
  align-items: center;
  margin: 10px;
  color: #5f6562;
  cursor: pointer;
}
.selected {
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  /* or 17px */

  display: flex;
  align-items: center;

  /* HD Orange */

  color: #f96302;
}
.multi-select-dropdown {
  position: absolute;
  width: 336px;
  height: 300px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  padding: 20px;
  margin: 0;
  margin-top: 15px;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.multi-select-dropdown::-webkit-scrollbar {
  display: none;
}
.main {
  background: #ffffff;
  border-radius: 10px;
  width: 483px;
  height: 617px;
  padding: 70px;
  border: solid black 2px;
}
.confirmation {
  background: #ffffff;
  border-radius: 10px;
  width: 483px;
  height: 387px;
  padding: 70px;
  border: solid black 2px;
  margin-bottom: 25%;
}

.title {
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 750;
  font-size: 48px;
  line-height: 58px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #000000;
}
.text {
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 127.5%;
  /* or 20px */

  color: #5f6562;
}
.instructions {
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 127.5%;
  /* or 18px */

  display: flex;
  align-items: center;

  color: #982724;
}
.button-area {
  display: flex;
}
.select {
  background: #ffffff;
  /* Alert */
  color: #c0c2c4;
  border: 2px solid #b32d22;
  width: 336px;
  height: 51px;
}
.input {
  width: 336px;
  height: 80px;
  left: 71px;
  top: 373px;

  background: #ffffff;
  border: 2px solid #e5e5e5;
}
.back {
  border: 1px solid #e5e5e5;
  border-radius: 30px;
  width: 165.48px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
  color: #000000;
}

.submit {
  background: #f96302;
  border-radius: 30px;
  width: 165.48px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
}
.continue {
  background: #f96302;
  border-radius: 30px;
  width: 165.48px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
  margin-top: 35px;
}
.placeholder {
  color: #c0c2c4;
}
</style>
